<template>
  <div
    v-if="organization && organization.data && authorizations"
    class="container mb-5"
  >
    <b-col
      lg="10"
      sm="12"
      xs="12"
      md="8"
      offset-lg="1"
      offset-md="2"
    >
      <b-card
        class="hubu-bg-red"
        :header="$t('auth.account')"
        header-tag="header"
      >
        <h3 class="text-center">
          {{ $t("auth.personal_information") }}
        </h3>
        <b-form @submit="updateAccount">
          <div v-if="errors.invalid">
            {{ errors.invalid }}
          </div>

          <b-row>
            <b-col
              lg="6"
              sm="12"
            >
              <b-form-group
                id="input-group-1"
                :label="$t('auth.first_name')"
                description=""
              >
                <b-form-input
                  v-model="form.first_name"
                  type="text"
                  required
                  :placeholder="$t('auth.first_name')"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col
              lg="6"
              sm="12"
            >
              <b-form-group
                :label="$t('auth.last_name')"
                description=""
              >
                <b-form-input
                  v-model="form.last_name"
                  type="text"
                  required
                  :placeholder="$t('auth.last_name')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group
            :label="$t('auth.email_label')"
            description=""
          >
            <b-form-input
              v-model="form.email"
              type="email"
              required
              :placeholder="$t('auth.email_label')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            :label="$t('auth.password_label')"
            :description="$t('auth.password_label')"
          >
            <b-input-group>
              <b-form-input
                id="input-2"
                v-model="form.password"
                :type="form.password_field.type"
                required
                minlength="6"
                :placeholder="$t('auth.password_label')"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="outline-secondary"
                  @click="showPassword"
                >
                  <b-icon :icon="form.password_field.icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-row>
            <b-col
              lg="6"
              sm="12"
            >
              <b-form-group
                :label="$t('auth.new_password_label')"
                :description="$t('auth.new_password_label')"
              >
                <b-form-input
                  v-model="form.newPassword"
                  type="password"
                  :placeholder="$t('auth.enter_new_password_label')"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col
              lg="6"
              sm="12"
            >
              <b-form-group
                :label="$t('auth.new_passwordConfirm_label')"
                :description="$t('auth.new_passwordConfirm_label')"
              >
                <b-form-input
                  v-model="form.passwordConfirmation"
                  type="password"
                  :placeholder="$t('auth.enter_new_passwordConfirm_label')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <div>
            <b-button
              class="hubu-btn-red mr-2"
              size="md"
              type="submit"
              variant="primary"
            >
              {{ $t("submit") }}
            </b-button>
          </div>
        </b-form>

        <h3 class="text-center m-4">
          {{ $t("organization.organization") }}
        </h3>
        <b-form @submit="updateOrganization">
          <b-form-group
            :label="$t('organization.name')"
            description=""
          >
            <b-form-input
              v-model="organization.data.name"
              type="text"
              required
              :placeholder="$t('organization.name')"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            v-if="authorizations.logo"
            id="input-group-file"
            :label="$t('organization.logo')"
            label-for="input-file"
            description=""
          >
            <b-form-file
              id="input-file"
              ref="logo"
              :placeholder="$t('organization.logo_upload_label')"
              :drop-placeholder="$t('organization.drop_placeholder')"
              name="logo"
              accept="image/*"
              @change="handleFileChange"
            ></b-form-file>
          </b-form-group>
          <div v-if="isFreeOrGold">
            <template v-if="typeof organization.data.logo != 'undefined'">
              <img
                v-if="fakeLogoUrl != null || organization.data.logo != null"
                class="company-logo"
                :src="
                  fakeLogoUrl != null
                    ? fakeLogoUrl
                    : uploadedLogoUrl
                "
              />
            </template>

            <div>
              <b-button
                v-if="organization.data.logo != null && !ongoingRequest"
                class="hubu-btn-red mr-2"
                size="md"
                type="submit"
                variant="primary"
                @click="confirmRemoveLogo"
              >
                {{ $t("remove_logo") }}
              </b-button>

              <b-button
                v-if="!ongoingRequest"
                class="hubu-btn-red mr-2"
                size="md"
                type="submit"
                variant="primary"
              >
                {{ $t("submit") }}
              </b-button>

              <b-spinner
                v-if="ongoingRequest"
                label="Loading..."
              ></b-spinner>
            </div>
          </div>
        </b-form>

        <hr class="pb-1" />

        <div v-if="authorizations.seeOrganizationUser">
          <h5 class="text-center mt-4">
            {{ $t("auth.games_info") }}
          </h5>

          <b-row
            v-for="(userHat, index) in organization.data.hats"
            :key="index"
          >
            <b-col
              lg="8"
              sm="6"
              class="text-left"
            >
              <router-link
                :to="{ name: 'ManageAccounts' }"
                tag="a"
                class="text-blue"
              >
                {{ userHat.user.first_name + " " + userHat.user.last_name }}
              </router-link>
            </b-col>
            <b-col
              lg="4"
              sm="6"
              class="text-right"
            >
              <span class="text-bold">{{ $t("organization.games_played") }}:</span>
              {{ gamesPlayed[userHat.id] || 0 }}
            </b-col>
          </b-row>
        </div>

        <hr class="pb-1" />
        <h6 class="mt-5">
          <span class="text-bold text-red">{{ getText('number_of_games_left') }}:</span>
          {{ organization.data.credits }}
        </h6>

        <b-row>
          <b-col
            lg="12"
            sm="12"
            class="text-center mt-3 mb-3"
          >
            <b-link :href="appText('Buy_url')">
              <b-button
                class="btn hubu-btn-red mr-2 btn-primary"
                size="md"
              >
                {{ $t("auth.register") }}
              </b-button>
            </b-link>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-modal
      id="confirm-modal"
      :title="$t('modal.confirm.title')"
      header-bg-variant="red"
      header-text-variant="white"
      body-bg-variant="white"
      body-text-variant="dark"
      footer-bg-variant="white"
      footer-text-variant="red"
    >
      <b-container fluid>
        <b-row class="mb-1 text-center">
          <b-col cols="12">
            <h5 class="text-left">
              {{ heading }}
            </h5>
            <hr />
            {{ message }}
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <p class="float-left"></p>
        </div>
        <div class="w-100">
          <b-button
            variant="outline-red"
            size="lg"
            class="float-right"
            @click="$bvModal.hide('confirm-modal')"
          >
            {{ $t("submit") }}
          </b-button>
        </div>
      </template>
    </b-modal>
    <Prompt-Modal
      :title="$t('remove_logo')"
      :message="$t('remove_logo_body')"
      @yes="removeLogo"
    ></Prompt-Modal>
  </div>
</template>

<script>
import PromptModal from "../PromptModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Account",

  components: {
    PromptModal,
  },

  data () {
    return {
      modal: {
        Heading: "",
        Message: "",
      },
      fakeLogoUrl: null,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        newPassword: null,
        passwordConfirmation: null,
        password_field: {
          type: "password",
          icon: "eye-slash",
        },
      },
      organization: {
        data: null,
        logo: null,
        totalGamesPlayed: 0,
        gamesData: [],
      },
      errors: {
        invalid: null,
      },
      ongoingRequest: false,
      gamesPlayed: null,
    };
  },

  computed: {
    ...mapGetters(["organizationLogo", "getOrganization", "isFreeOrGold", "authorizations", "gamesPerHat"]),
    nameChanged () {
      return this.organization.data.name !== this.getOrganization.name;
    },
    games () {
      return this.$store.getters.getMyGames;
    },
    uploadedLogoUrl () {
      return this.organizationLogo;
    },
    heading: {
      set (v) {
        this.modal.Heading = v;
      },
      get () {
        return this.modal.Heading;
      },
    },
    message: {
      set (v) {
        this.modal.Message = v;
      },
      get () {
        return this.modal.Message;
      },
    },
    user () {
      return this.$store.getters.getUserData;
    },
  },

  async mounted () {
    await this.$store.dispatch("getOrganizations").then(() => {
      this.organization.data = this.$store.getters.getOrganizations[0];
    });

    const gamePlayed = await this.$store.dispatch("allGamesPlayed", { hats: this.organization.data.hats.map(hat => hat.id) });
    this.gamesPlayed = gamePlayed;
  

    this.form.email = this.user.email;
    this.form.first_name = this.user.first_name;
    this.form.last_name = this.user.last_name;
    
    this.$store.dispatch("getMyGames");
  },

  methods: {
    ...mapActions(["addFile"]),
    getText (val) {
      return this.$store.getters.getAppTexts[val].content;
    },
    confirmRemoveLogo (event) {
      event.preventDefault();
      this.$bvModal.show("prompt-modal");
    },

    removeLogo () {
      this.ongoingRequest = true;
      this.$store.dispatch("removeLogo", this.organization.data.logo.id)
        .then(() => {
          this.ongoingRequest = false;
          this.$toast.open(this.$t("logo_deleted"));
          this.organization.data.logo = null;
          this.fakeLogoUrl = null;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.open({ message: "Error", type: "error" });
        });
    },

    handleFileChange (e) {
      const file = e.target.files[0];
      this.fakeLogoUrl = URL.createObjectURL(file);
      this.uploadedImage = e.target.files[0];
      this.$forceUpdate();
    },

    updateOrganization (e) {
      e.preventDefault();
      this.organization.logo = this.$refs.logo.files[0];
      this.organization.formData = new FormData();
      this.organization.formData.append("files.logo", this.organization.logo);
      this.organization.formData.append("name", this.organization.data.name);
      this.organization.formData.append("id", this.organization.data.id);
      this.ongoingRequest = true;
      this.$store
        .dispatch("updateOrganization", {
          formData: this.organization.formData,
          organizationData: this.organization.data,
        })
        .then(() => {
          this.$store.dispatch("getOrganizations")
            .then(() => {
              this.ongoingRequest = false;
              this.$toast.open("Organization updated");
              this.fakeLogoUrl = null;
              this.$forceUpdate();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.open({ message: "Error", type: "error" });
        });
    },

    updateAccount (e) {
      e.preventDefault();
      this.$store
        .dispatch("userLogin", {
          email: this.user.email,
          password: this.form.password,
        })
        .then(() => {
          if (this.form.newPassword == this.form.passwordConfirmation) {
            this.$store
              .dispatch("updateAccount", {
                identifier: this.user.email,
                first_name: this.form.first_name,
                last_name: this.form.last_name,
                password:
                  this.form.newPassword == null
                    ? this.form.password
                    : this.form.newPassword,
                email: this.form.email,
              })
              .then(() => {
                this.heading = this.$t("modal.confirm.title");
                this.message = this.$t("account_updated");
                this.$bvModal.show("confirm-modal");
              });
          } else {
            if (this.form.newPassword == null) {
              this.$store
                .dispatch("updateAccount", {
                  identifier: this.user.email,
                  password:
                    this.form.newPassword == null
                      ? this.form.password
                      : this.form.newPassword,
                  email: this.form.email,
                })
                .then(() => {
                  this.heading = this.$t("modal.confirm.title");
                  this.message = this.$t("account_updated");
                  this.$bvModal.show("confirm-modal");
                });
            } else {
              this.heading = this.$t("modal.confirm.title");
              this.message = this.$t("passwords_no_match");
              this.$bvModal.show("confirm-modal");
            }
          }
        })
        .catch(() => {
          this.heading = this.$t("modal.confirm.title");
          this.message = this.$t("Auth.form.error.invalid");
          this.$bvModal.show("confirm-modal");
        });
    },
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object"
        ? this.$store.getters.getAppTexts[text].content
        : text;
    },
    showPassword () {
      if (this.form.password_field.type === "password") {
        this.form.password_field.type = "text";
        this.form.password_field.icon = "eye";
      } else {
        this.form.password_field.type = "password";
        this.form.password_field.icon = "eye-slash";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.company-logo {
  height: 80px !important;
  margin: 0px 0px 24px 15px;
}

.hubu-bg-red {
  border: 1px solid $red;
}

.hubu-btn-red {
  background-color: $red;
  color: $white;
  font-weight: bold;
  border: 0px;
}

.btn-white {
  background-color: $white;
  color: $red;
  font-weight: bold;
  border: 1px solid $red;
}

header {
  background-color: $red;
  border-color: $red;
  font-size: 24px;
  color: $orange;
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.text-blue {
  color: rgb(74, 113, 241) !important;
}
</style>
